@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap');

html{
  height: 100%;
}


*, *::before, *::after {
  box-sizing: border-box;
}

 body {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background-color: #22333e;
   /* #22333e; */
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  color: white; 
  min-height: 70vh; 
  max-height: 100%;
  overflow: hidden; 
 }  


.container {
  background-color: #22333e;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  max-width: 100vw;
  color: white;
  max-height: 80vh;   
  margin: 0 0;
  padding: 0 0px;
  /* flex-grow: 1;  */
  min-height: 60vh  ;
  overflow: hidden;
}


.full-height-grow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.brand-logo {
  display: flex;
  align-items: center;
  font-size: 1.25em;
  color: inherit;
  text-decoration: none;
}

.brand-logo-name {
  margin-left: 1rem;
  font-size: 1.25em;
  font-family: Poppins, sans-serif !important;
}

.logo {
  width: 120px;
  height: 120px;
  margin-left: 4rem;
}

.main-nav ul, .footer-nav ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav a, .footer-nav a {
  color: inherit;
  text-decoration: none;
  padding: 1rem;
}

.main-nav a:hover, .footer-nav a:hover  {
  color: #adadad;
}

.main-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-header {
  height: 140px;
  /* font-family: Poppins, sans-serif !important; */
}


.social-link1 {
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  background: url('./images/twitter.svg');
  background-repeat: no-repeat;
}


.social-link2 {
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  background: url('./images/facebook.svg');
  background-repeat: no-repeat;
}



/* .social-link img {
  width: 1em;
  margin-right: .25rem;
} */

.main-footer .container {
  display: flex;
  justify-content: space-between;
  background-color:#202027;
  height: 10vh !important;
}


.title {
  font-weight: 500;
  font-size: 3em;
  margin-bottom: 1rem;
  margin-top: 0;
}

.subtitle {
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 2rem;
}

.jbtn {
  color: white;
  width:125px;
  background-color: #1762A7;
  padding: .75rem 1.5rem;
  border-radius: .5rem;
  text-decoration: none;
  font-size: .9rem;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}
.jbtn:hover {
  background-color: #4474a1;
}


.btn {
  color: white;
  background-color: #1762A7;
  padding: .75rem 1.5rem;
  border-radius: .5rem;
  text-decoration: none;
  font-size: .9rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: #2180d8;
}

.btn:focus {
  background-color: #2796ff;
}