  
  a {
    /* color: #2199df; */
    color:white;
    text-decoration: unset;
  }
   
  ul {
    margin-left: 1.5rem;
    list-style: none;
  }
  
  button,
  input[type="submit"] {
    border: none;
    background: none;
    color: unset;
    cursor: pointer;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  li {
    margin-top: 1em;
    margin-bottom: 1em;
   /* background-color:#22333e; */
  }
  
  /* general */
  
   
 /* .page {
    padding: 1rem;
  } */
  
 /*  .container {
    margin: 0 auto;
    max-width: 900px;
  }  */
  
  /* header */
  
  #header {
    height: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:  #22333e;
    font-family: Poppins, sans-serif;
  } 
  
  #header .title {
    margin: 0;
    padding: 0 1rem;
    color: #ffffff;
  }
  #header .auth-options {
    height: 100%;
    display: flex;
    margin-right:4rem;
  }
 
  #header .auth-options button {
    padding: 0 1rem;
    background-color: #22333e;
    color: #ffffff;
    font-size: 1.2rem;
    } 
  
  #header .auth-options button:hover {
    color: #adadad;
    background-color: #22333e;
    transform :scale(1.05);
    align-items:space-between;
  }
  
.AuthBtn{
  margin-top: 3em;
  padding:15px;
  height: 40px ;
 }


  /* form */
  
  .form label,
  .form input {
    display: block;
  }
  .form input {
    margin-bottom: 1rem;
  }
  .form label {
    margin-bottom: 0.2rem;
  }
 
  
  /* todo list */
  
  .todo-list ul {
    list-style: disc;
    cursor: pointer;
  }
  
  .todo-list li:hover {
    text-decoration: line-through;
  }
  
  /* error notice */
  
  .error-notice {
    margin-left:60vw ;
    /* margin: 1rem 0; */
    padding: 0.5rem;
    border: 1px solid #e07c7c;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #df4d4d;
    width:350px; 
    z-index:4;
  }
  .error-notice button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ee0c0c;
    color: #ffffff;
  }
  
  /* media queries */
  
  @media screen and (max-width: 550px) {
    #header .title {
      font-size: 1.2rem;
    }
    #header .auth-options button {
      padding: 0 0.5rem;
      font-size: 0.9rem;
    }
  }
  