.home-main-section {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
}

.home-main-section .img-wrapper {
  
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  justify-content: top;
  margin-top: 87px;
  margin-left: 170px;
  z-index: 10;
}

.home-main-section .lady-image {
  background-image: url(images/landing-page-happyguy.png);
  flex-grow: 1;
  max-height: 70vh;
  min-width: 30vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  z-index: 2;
}

.home-main-section .call-to-action {
  /* flex-grow: 1;  */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* margin: .1rem; */
  /* align-self: center; */
  justify-content: top;
  margin-top:205px;
  margin-right: 270px;
}

@media (max-width: 900px) {
  .home-main-section .img-wrapper {
    display: none;
  }

  .home-main-section {
    position: absolute;
    top:15px;
    justify-content: top;
    
  }
}

.home-page-circle-1 {
  position: absolute;
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
  background-color: rgba(20, 197, 43, 0.1);
  bottom: 5vw;
  left: 5vw;
  z-index: 1;
}

.home-page-circle-2 {
  position: absolute;
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
  background-color: rgba(27, 155, 240, 0.1);
  top: 15vh;
  left: 30vw;
  z-index: 1;
}

.home-page-circle-3 {
  position: absolute;
  overflow: hidden;
  width: 45vw;
  height: 45vw;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background-color: rgb(52, 87, 178, .1);
  z-index: -1;
}

.home-page-circle-3::before {
  content: '';
  transform: translate(40%, 40%);
  position: absolute;
  height: 70%;
  width: 70%;
  border-radius: 50%;
  background-color: rgb(52, 87, 178, .1);
  bottom: 0;
  right: 0;
}
