.discover-main-section {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between;
    /* flex-grow: 1; */
    margin-top:90PX ;
    margin-left: 170px;
    padding-bottom: 120px ;
  }
  
  .discover-main-section .covers-image {
    width: 30vw;
    margin-left: 2rem;
    height: 25vw;
    max-width: 350px;
    margin-right:170px;
      }
  
  .icon-section {
    display: flex;
  }
  
  .icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: .5rem;
    padding: .75rem;
    width: 100px;
    height: 100px;
    background-color: #202027;
    margin-bottom: 2rem;
  }
  
  .icon img {
    width: 40px;
    height: 40px;
  }
  
  @media (max-width: 800px) {
    .discover-main-section {
      flex-direction: column;
      justify-content: center;
    }
  
    .discover-main-section .covers-image {
      margin-top: 2rem;
      height: 30vh;
      width: auto;
    }
  }