
 footer{
    position:absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: auto;
    width:100%;
    background-color:#202027; 
    height: 85px;
    z-index: 3;
  }

  
  .left-nav{
      display: flex;
      flex-direction: row;
      width:40vw;
  }

  .right-nav{
    display: flex;
    flex-direction: row;
    position:absolute;
    top:0;
    right:0 ;
    width:40vw;
}

  .footerLinks {
    text-align: center;
 }
 .footerLinks ul {
    display: flex;
    flex-direction: row;
    background-color:#202027;
    padding: 0;
    list-style-type: none;
    margin: 0;
 }
 .footerLinks li {
    width:125px;
    background-color:#202027;
 }

 /* .footerLinks li:hover{
    transform :scale(1.0);
    transition:ease 0.25s all;
}  */

 .footerLinks a {
     /* color: #d9d9d9; */
     text-decoration: none;
     font-size: 13px;
 }
 .copyright {
     text-align: center;
 }
 
 .copyright p {
     margin: 0;
     color: #b3b3b3;
     font-size: 11px;
 }
/* .footer-li {
align-items: top;
padding: 5px 5px;
background-color:#202027; 
height: 65px;
width: 120px;
} */

/* .footer-nav-left {
    height: 90px;
}


.main-footer {
    position:absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100vw;
}

.main-footer {
    height: 90px;
    background-color: #202027;
    font-weight: bold;
  }

.footer-container{
    display: flex;

}

.main-footer .footer-nav-right {
    display: flex;
    align-items:right;
    
}
 */