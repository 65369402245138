ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* ul {
  width: 200px;
  display: grid;
  flex-direction: column;
  background: white;
  padding: 10px;
  border-radius: 15px;
} */

.dscroller{
  max-height: 650px;
  overflow-y: scroll;
}
.dscroller::-webkit-scrollbar {
    display: none;
}

.bscroller{
  max-height: 1200px;
  overflow-y: scroll;
  }

.bscroller::-webkit-scrollbar {
 display: none;
}



ul{
  margin-top: 3%;
  margin-left: 3%; 
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: auto auto auto; 
  column-gap: 3%;
  row-gap: 5px;
  color:white;
}

li:hover{
    transform :scale(1.05)
} 

li {
  background-color: #202027;
   /* rgba(214, 214, 214, 0.5);  */
  padding: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
  font-size: 16px;
}

li:last-child {
  margin-bottom: 0px;
}

.avatar {
  width: 80px;
  height: 80px;
  background-color: #666;
  border-radius: 20px;
  float: left;
}

.mimg{
width:80px;
height: 80px;
}

.row {
  width: 100%;
  height: 8px;
  background-color:#999;
  border-radius: 10px;
  margin-top: 12px;
}

@media only screen and (max-width:480px){
    ul{
        display: grid;
        margin-left: 5%;   
        grid-template-columns: 45% 45% ;
        grid-template-rows: auto auto ; 
        column-gap: 5%;
        row-gap: 5px;
        color:white;
      }
      
      
}