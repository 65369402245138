*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Poppins, sans-serif;
  }
  
  /* body {
    background: linear-gradient(
      90deg,
      rgb(124, 137, 143) 0%,
      #9b9e9a 100%
    );
  }
   */

   /* .text{
    max-height:1500px ;
} 


.todolistContainer{
  height:1500px  
} */

   
  .todo-app {
    position: absolute;
    top:-110px;
    right:200px;  
    /*right: 25%;
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);*/
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content:top;
    width: 60vw;
    max-height: 100%;
    /* min-height: 1200px; */
    /* background:#202027; */
    background:#22333e;
    text-align: center;
    margin: 128px auto;
    border-radius: 10px;
    padding-bottom: 32px;
  }
  
  h1 {
    margin: 32px 0;
    color: #fff;
    font-size: 24px;
  }
  
  .complete {
    text-decoration: line-through;
    opacity: 0.4;
  }
  
  .pagenav{
    display: inline-flex;
    justify-content: center;
    gap: 10px;
    }
  
  .pgNo{
    padding: inline;
    font-size: 20px;
    width:70px;
  }
  
  .postBtn{
    border-radius:15%;
    margin-top:5px ;
    padding:5px;
    width:65px;
    background-color: aqua;
  }
  
  
  .todo-form {
    margin-bottom: 32px;
  }
  
  .todo-input {
    padding: 14px 32px 14px 16px;
    border-radius: 4px 0 0 4px;
    border: 2px solid #0c6dff;
    outline: none;
    width: 40vw;
    background: transparent;
    color: #fff;
  }
  
  .todo-input::placeholder {
    color: #e2e2e2;
  }
  

 .input-priority {
   color:greenyellow;
 }

 .set-priority{
  padding: 14px 32px 14px 16px;
  border-radius: 4px 0 0 4px;
  border: 2px solid red;
  outline: none;
  width: 7vw;
  background: transparent;
  color:greenyellow;
}



.todo-red {
  background-color: greenyellow;
}


  .todo-input-p {
    padding: 14px 32px 14px 16px;
    border-radius: 4px 0 0 4px;
    border: 2px solid red;
    outline: none;
    width: 7vw;
    background: transparent;
    color: white;
  }


  .todo-button {
    padding: 12px;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    outline: none;
    background: linear-gradient(
      90deg,
      #0c6dff 0%,
      rgb(0, 17, 250) 100%
    );
    color: #fff;
    text-transform: capitalize;
  }
  
  .todo-input.edit {
    border: 2px solid #149fff;
  }
  
  .todo-button.edit {
    background: linear-gradient(
      90deg,
      rgba(20, 159, 255, 1) 0%,
      rgba(17, 122, 255, 1) 100%
    );
    padding: 16px 22px;
  }
  
  .todo-container {
    display: flex;
    flex-direction: row;
    position: relative;
  }
  
  .todo-row {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px auto;
    color: #fff;
    background: linear-gradient(
      90deg,
      #202027 0%,
      #4d4d4e 100%
     /* rgb(49, 70, 46) 0%,
     rgb(88, 107, 92) 100% */
    );
  
    padding: 16px;
    border-radius: 5px;
    width: 90%;
  }
  
  .todo-row:nth-child(4n + 1) {
    background: linear-gradient(
      90deg,
      rgb(102, 98, 98) 0%,
      rgb(168, 165, 167) 100%
    );
  }
  
  .todo-row:nth-child(4n + 2) {
    background: linear-gradient(
      90deg,
      #202027 0%,
      #4d4d4e 100%
      /* rgb(49, 70, 46) 0%,
      rgb(88, 107, 92) 100% */
      /* rgb(156, 156, 197) 0%,
      rgb(209, 205, 201) 100% */
    );
  }
  
  .todo-row:nth-child(4n + 3) {
    background: linear-gradient(
      90deg,
      rgb(102, 98, 98) 0%,
      rgb(168, 165, 167) 100%
      /* rgb(152, 185, 209) 0%,
      rgb(211, 217, 224) 100% */
    );
  }
  
  .icons {
    display: flex;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
  }
  
  .delete-icon {
    margin-right: 5px;
    color: #fff;
  }
  
  .edit-icon {
    color: #fff;
  }
  
  @media only screen and (max-width : 690px) 
  {
    .todo-app {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content:flex-start;
      width: 90vw;
      max-height: 100%;
      background: #161a2b;
      text-align: center;
      margin: 10px auto;
      border-radius: 10px;
      padding-bottom: 10px;
    }
    /* body {
      background: linear-gradient(
        90deg,
        rgb(4, 159, 231) 0%,
        #7ab1f0 100%
      ); 
    }*/
    } 
  
  
  