.RegBtn{
    background-color: #3abcb1;
      color: white;
      border-radius: 8px;
      width: 50%;
      font-size: .71em;
      padding: 5px;
      margin: 0;
      /* border: 1px solid #49b4d4; */
      }

.RegBtn:hover { background-color: #70bdb6;
  color: azure;
}

 
  .join-form input:focus {
    border: 1px solid #50a4e9;
    box-shadow: inset 0px 0px 5px rgba(38, 142, 228, 0.5);
    outline: none;
    }
 
 .join-form input[type="submit"] {
    width: 100%;
    padding: 0.3rem 0.5rem;
    /* border: 1px solid #6aa051; */
    border-radius: 8px;
    background-color:#1762A7;
    font-size: 1.1rem;
    }

    .join-form input[type="submit"]:hover {
      background-color:#5383b1;
    } 
.join-form input[type="text"],
.join-form input[type="email"],
.join-form input[type="password"] {
    width: 100%;
    padding: 0.3rem;
    border: 1px solid #c2c2c2;
    border-radius: 8px;
    background-color: #202027;
    font-size: 1.1rem;
  }


.join-main-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }
  
  .join-text {
    font-size: 3.5em;
    font-weight: bold;
    margin-left: 160px;
    margin-top: 150px;
  }
  
/* For Registration Component */
  .register-text {
    font-size: 3.5em;
    font-weight: bold;
    margin-left: 160px;
    margin-top: 110px;
  }



  .accent-text {
    color: #3abcb1;
  }


.join-form {
    background-color:#202027;
     /* #202027; */
    width: 35vw;
    max-width: 50%;
    padding: 2rem 4rem;
    border-radius: 20px;
    margin-right: 100px ;
  }
  
  .input-group {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
  }
  
  .input-group:last-child {
    margin-bottom: 0;
  }
  
  .input-group label {
    font-weight: bold;
    margin-bottom: .5rem;
  }
  
  .input-group input {
    outline: none;
    background: none;
    border: 1px solid #2E3852;
    font-size: 1em;
    padding: .5em;
    color: inherit;
    border-radius: 5px;
  }
  
  .input-group input:focus {
    border-color: #596da0;
  }
  
  @media (max-width: 900px) {
    .join-main-section {
      flex-direction: column;
      justify-content: center;
    }
  
    .join-form {
      width: 80vw;
      max-width: inherit;
      margin: 2rem 0;
    }
  }
  
  .join-page-circle-1 {
    position: absolute;
    overflow: hidden;
    width: 35vw;
    height: 35vw;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  
  .join-page-circle-1::before {
    content: '';
    transform: translateX(-20%);
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: rgba(61, 165, 197, 0.11);
    z-index: 1;
  }
  
  .join-page-circle-2 {
    position: absolute;
    overflow: hidden;
    width: 30vw;
    height: 30vw;
    top: 0;
    left: 20vw;
    z-index: 1;
  }
  
  .join-page-circle-2::before {
    content: '';
    transform: translateY(-30%);
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: rgba(8, 49, 230, 0.1);
  }