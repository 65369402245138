.nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 10vh;
    background-color:#22333e;
    /* background-color:#202027; */
    margin-right:0px ;
    border-radius:15%;
    }



.nav-links{
    width:40%;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    color:white;
   }

   .nav-links :active{
        color:grey;
   }

   .nav-links :hover {
    color:#adadad;
    transform:scale(1.05);
   }